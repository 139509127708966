import React, {Component} from 'react';
import isEmpty from 'lodash.isempty';
import GoogleMap from './components/GoogleMap';
import MARKER_CENTER, {default_zoom} from './const/marker_center';
import './LBDMap.css';
import MarkerClusterer from "@google/markerclustererplus";

const EXCLUDE_FROM_INFO_WINDOW = [
  'structure_number',
  'description',
  'latitude',
  'longitude',
  'image',
  'illuminated'
];

const getInfoWindowString = place => `
  <div class="lbd-info-window">
    ${typeof place.lbd.image !== 'undefined' && typeof place.lbd.image.value !== 'undefined'
    ? `<img alt="Billboard By a Road" src="${place.lbd.image.value.url}" />`
    : ''}
    <div class="lbd-info-window-title">Unit: ${place.lbd.structure_number.value}</div>
    <table class="table">
      <tbody>
      ${Object.keys(place.lbd).filter(key => EXCLUDE_FROM_INFO_WINDOW.indexOf(key) === -1).map(key => {
  if (place.lbd[key].value !== '') {
    return `
            <tr class="${place.lbd[key].label.toLowerCase().replace(/ /g, '-')}">
              <td style="font-weight: bold">${place.lbd[key].label}</td>
              <td>${place.lbd[key].value}</td>
            </tr>
          `
  }
  return '';
}).join('')}
      </tbody>
    </table>
    <a href=${place.link} class="detail-button">More Details</a>
  </div>
`;

class LBDMap extends Component {

  state = {
    places: []
  };

  componentDidMount() {
    fetch('/wp-json/wp/v2/billboard?_per_page=300')
        .then(response => response.json())
        .then(places => {
          places.forEach(result => result.show = false);
          this.setState({places});
        });
  }

  handleApiLoaded = (map, maps, places) => {
    const markers = [];
    const infowindows = [];

    for(const place of places) {

      let latLng = new maps.LatLng(
          parseFloat(place.lbd.latitude.value),
          parseFloat(place.lbd.longitude.value)
      );

      // Slightly offset overlapping markers
      markers.forEach((marker, i) => {
        var pos = marker.getPosition();
        if (latLng.equals(pos)) {
          var a = 360.0 / markers.length;
          // var newLat = pos.lat() + -.00004 * Math.cos((+a*i) / 180 * Math.PI);  //x
          var newLng = pos.lng() + -.001 * Math.sin((+a*i) / 180 * Math.PI);  //Y
          latLng = new maps.LatLng(pos.lat(),newLng);
        }
      });

      markers.push(new maps.Marker({position: latLng, map,}));
      infowindows.push(new maps.InfoWindow({content: getInfoWindowString(place),}));
    }

    markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        infowindows[i].open(map, marker);
        markers.forEach((marker, j) => {
          if (i !== j) {
            infowindows[j].close(map, marker);
          }
        });
      });
    });

    new MarkerClusterer(map, markers, {
      maxZoom: 15,
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    });
  };

  render() {
    const {places} = this.state;

    return (
        <div className="lbd-map">
          {!isEmpty(places) && <GoogleMap
              defaultZoom={default_zoom}
              defaultCenter={MARKER_CENTER}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({map, maps}) => this.handleApiLoaded(map, maps, places)}
          />}
        </div>
    );
  }
}

export default LBDMap;