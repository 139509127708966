import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import LBDMap from './LBDMap';
import LBDProposal from "./LBDProposal";

const lbdMapEl = document.getElementById('lbd-map');
if(lbdMapEl !== null) {
  ReactDOM.render(
      <LBDMap />,
      lbdMapEl
  );
}

const lbdProposalEl = document.getElementById('lbd-proposal');
if(lbdProposalEl !== null) {
  ReactDOM.render(
      <LBDProposal />,
      lbdProposalEl
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
